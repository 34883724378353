<template>
  <div v-if="jwtAccessValid() && userProfile.checked" class="mt-0">
    <div style="
    float: left;
    min-width: 200px;
    ">
      <ul class="list-group">
        <li v-for="task in tasks" :key="task._id" 
        class="list-group-item px-0 py-0"
        >
          <button 
          @click="initTask(task)"
          :class="{'active-task': (task._id == currentTask._id)}"
          type="button" 
          class="btn w-100 message-left" 
          >
            {{ task.desc }}
            <p class="text-muted authtor mb-0 message-left">{{ task.acctClient.fullname }}</p>
          </button>
        </li>
      </ul>
    </div>

    <div class="card " style="">
      <div class="card-body">
        <div class="card-title text-center" id="task-desc">
          <h3 v-if="currentTask.desc">{{ currentTask.desc }}</h3>
          <h3 v-if="!currentTask.desc" class="text-muted">Выберите заявку</h3>
          <hr />
        </div>
        <div class="card-body messages">
          <div v-for="(msg, index) in currrentTaskMessages" :key="index" :id="`message-${index}`"
          class="pt-1"
          :class="{'message-left': !isAuthtor(msg), 'message-right': isAuthtor(msg)}"
          >
            <div class="rounded px-1" :class="{'self-msg': isAuthtor(msg), 'peer-msg': !isAuthtor(msg)}">
              <p v-if="!isAuthtor(msg)" class="text-muted authtor mb-0">{{ msg.user.fullname }}</p>
              <p class="mb-0">
                {{ msg.text }}
              </p>
              <p class="text-muted message-date mb-0">
                {{ new Date(msg.date).toTimeString().substr(0, 5) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" id="send-message">
        <form @submit.prevent="sendMessage">
          <div class="gorm-group pb-3">
            <input type="text" v-model="text" class="form-control" />
          </div>
          <button type="submit" class="btn btn-success">Отправить</button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable no-unused-vars*/
import io from 'socket.io-client'
import { ref, onMounted, computed, watch, nextTick } from "vue"
import useCommonMixin from '@/components/commonMixin.js'
import { useStore } from 'vuex';

export default {
  name: 'Chat',

  setup() {
    const user = ref('')
    const text = ref('')
    const messages = ref([])
    const store = useStore()
    const socket = io(process.env.VUE_APP_SOCKET_BASE_URL, {auth: {token: `Bearer ${store.getters.jwtAccess()}`}})
    useCommonMixin()
    const { jwtAccessValid, userProfile } = useCommonMixin()
    const currentTask = ref('abc')
    //currentTask.value = Math.floor(Math.random() * 10);

    const sendMessage = (e) => {
      e.preventDefault()

      socket.emit('join', currentTask.value._id)

      socket.emit('send_message', 
        {
          task: currentTask.value._id,
          data: 
            {
              user: store.getters.userProfile._id,
              text: text.value
            }
        }
      );
      text.value = ''
    }

    const recieveMessage = () => {
      socket.on('connect_error', (error) => {
        if (error.data.type === 'UnauthorizedError') {
          console.log('User token has expired')
          console.log(error)
        }
      })
      
      socket.on('message', (data) => {
        messages.value.push(data)
      });    
    }

    const loadMessages = async () => {
      let response = await store.dispatch('FETCHmessages', currentTask.value._id)
      if (response.status) {
        messages.value = response.data
      } else {
        messages.value.push({text: 'Ошибка загрузки сообщений'})
      }
      scrollToLastMessage()
    }
    
    const initTask = (task) => {
      currentTask.value = task
      socket.emit('join', task._id)
      loadMessages(task._id)
    }

    const isAuthtor = (message) => {
      return store.getters.userProfile._id == message.user._id
    }

    const currrentTaskMessages = computed(() => {
      return messages.value.filter(item => item.task == currentTask.value._id)
    })

    const tasks = computed(() => store.getters.tasks)
    const messagesLength = computed(() => messages.value.length)

    const scrollToLastMessage = async () => {
      await nextTick()
      let index = messages.value.length - 1
      let message = document.getElementById(`message-${index}`)
      if (message) message.scrollIntoView()
    }

    watch(() => messagesLength.value, scrollToLastMessage)

    onMounted(()=> {
      recieveMessage()
      store.dispatch('FETCHtasks')
    })


    return {
      user,
      text,
      messages,
      sendMessage,
      jwtAccessValid,
      currentTask,
      tasks,
      initTask,
      isAuthtor,
      currrentTaskMessages,
      userProfile,
    }
  },
}
</script>

<style>
.active-task {
  background: #ebebeb !important
}

.self-msg {
  background: #dcf8c6 !important;
  margin-left: auto;
  margin-right: 0.1rem;
  max-width: 90%;
  min-width: 150px;
  display: inline-block;
  text-align: left;
}

.peer-msg {
  background: #e1e7dc !important;
  margin-left: 0.1rem;
  margin-right: auto;
  max-width: 90%;
  min-width: 150px;
  display: inline-block;
  text-align: left;
}

.authtor {
  font-size: 0.9rem;
}

.message-date {
  text-align: right;
  font-size: 0.8rem;
  padding-right: 2px;
}

.message-right {
  text-align: right !important;
}

.message-left {
  text-align: left !important;
}

.messages {
  overflow-y: scroll;
  height: calc(100vh - 311px) !important;
}

#task-desc {
  height: 56px;
}

#send-message {
  max-height: 110px;
}

</style>