<template>
<div v-if="jwtAccessValid() && userProfile.checked">
  <support-card v-if="isMobile"/>
  <support-table v-if="!isMobile"/>
</div>

</template>

<script>
import { computed } from "vue"
import SupportCard from '@/components/SupportCard.vue'
import SupportTable from '@/components/SupportTable.vue'
import useCommonMixin from './commonMixin.js'
import useSupportMixin from './supportMixin.js'

export default {
  name: "Support",
  components: {SupportCard, SupportTable},
  setup() {
    useCommonMixin()
    useSupportMixin()
    const { jwtAccessValid, userProfile } = useCommonMixin()
    const isMobile = computed(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    })

    return {
      isMobile,
      jwtAccessValid, 
      userProfile, 
    }
  },
};
</script>

<style>
</style>UsersTable