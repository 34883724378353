import { computed, nextTick } from 'vue'
import { useStore } from 'vuex';

export default () => {
    const store = useStore()
    const asyncForEach = async (array, callback) => {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    const isPasswordTemp = computed(() => {
      let passExp = new Date(store.getters.userProfile.passwordExp)
      let now = new Date(Date.now())
      console.log((Date.now() - passExp));
      console.log(new Date(store.getters.userProfile.passwordExp))
      console.log(new Date(Date.now()))
      console.log((passExp - now));

      return (passExp - now) <= 3600000
    })

    const isMobile = computed(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    })

    const dateFormat = (date) => {
      let result = ''
      if (date) {
        let dateFormated = new Date(date);
        result = dateFormated.toLocaleString()
      }
      
      return result;
    };

    const scrollToId = async (id) => {
      await nextTick();
      let element = document.getElementById(id);
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    return {
      jwtAccessValid: computed(() => store.getters.jwtAccessValid),
      userProfile: computed(() => store.getters.userProfile),
      isAdmin: computed(() => store.getters.isAdmin),
      isModerator: computed(() => store.getters.isModerator),
      asyncForEach,
      isPasswordTemp,
      isMobile,
      dateFormat,
      scrollToId,
  }
}
