<template>
  <div class="container pt-2 pb-2">
    <div style="horisontal-align: center">
      <input
        type="text"
        class="form-control w-50 center"
        placeholder="Поиск"
        v-model="searchText"
        @input="search"
      />

      <div
        v-for="req in registrationRequestFilter(client)"
        :key="req._id"
        class="card lh-1 mt-3 center"
        style="max-width: 350px"
      >
        <div class="card-body">
          <div class="row row-cols-2 pt-2">
            <div class="col text-muted"><small>ФИО</small></div>
            <div class="col text-muted"><small>Телефон</small></div>
            <div class="col pt-1">
              <span v-if="req.user">{{ req.user.fullname }}</span>
            </div>
            <div class="col pt-1">
              <span v-if="req.user">{{ req.user.phone }}</span>
            </div>
          </div>

          <div class="row row-cols-2 pt-2">
            <div class="col text-muted"><small>Email</small></div>
            <div class="col text-muted"><small>Email подвержден</small></div>
            <div class="col pt-1">
              <span v-if="req.user">{{ req.user.email }}</span>
            </div>
            <div class="col pt-1">
              <input
                v-if="req.user"
                class="form-check-input"
                type="checkbox"
                v-model="req.user.active"
                checked
                disabled
              />
            </div>
          </div>

          <div class="row row-cols-2 pt-2">
            <div class="col text-muted">
              <small>Наименование организации</small>
            </div>
            <div class="col text-muted"><small>ИНН</small></div>
            <div class="col pt-1">
              <span v-if="req.client"> {{ req.client.fullname }} </span>
            </div>
            <div class="col pt-1">
              <span v-if="req.inn">{{ req.inn }}</span>
            </div>
          </div>

          <div class="row row-cols-1 pt-2">
            <div class="col text-muted">
              <small>Должность</small>
            </div>
            <div class="col pt-1">
              <span v-if="req.position">{{ req.position }}</span>
            </div>
          </div>

          <div v-if="!req.checked" class="row row-cols-1 pt-2">
            <div class="col text-muted"><small>Роль</small></div>
            <div class="col pt-1">
              <select v-model="req.role" class="form-select">
                <option value="" selected>Выберите роль</option>
                <option v-for="role in roles" :key="role._id" :value="role._id">
                  {{ role.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="row row-cols-1 pt-2">
            <div class="col pt-1">
              <button
                :disabled="req.checked"
                @click="postRegistrationRequest(req)"
                class="btn btn-primary w-100"
              >
                <span v-if="req.checked"> Подтвержден </span>
                <span v-if="!req.checked"> Подтвердить </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  name: "RegistrationRequestCard",
  props: {client: String},
  setup() {
    const store = useStore();
    const registrationRequest = ref([]);
    const searchText = ref("");
    const { userProfile } = useCommonMixin();
    
    const init = async () => {
      let response = await store.dispatch("FETCHregistrationRequest");
      registrationRequest.value = response.status ? response.data : [];
      await store.dispatch("FETCHroles");
    };


    const postRegistrationRequest = async (req) => {
      if (!req.contactExists && userProfile.value.isAdminCs) {
        let confirm = window.confirm(
          `Внимание! ${req.user.fullname} - данного контактного лица нет в Контрагенте. Свяжитесь с администратором Контрагента и уточните актуальность нового контактного лица. Принять запрос на регистрацию?`
        );
        if (!confirm) {
          return;
        }
        
      }   
      
      let response = await store.dispatch("POSTregistrationRequest", req);
      if (response.status) {
        req.checked = true;
      }
      console.log(response);
    };

    const registrationRequestFilter = computed(() => {
      return (client) => {
        let result = [];
        if (client != "") {
          registrationRequest.value.forEach((item) => {
            if (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(client.toLowerCase()) >= 0 &&
              !item.checked
            ) {
              result.push(item);
            }
          });
        } else {
          result = registrationRequest.value.filter((item) => !item.checked);
        }
        
        let result2 = []
        if (searchText.value.length >= 2) {
          console.log(registrationRequest.value);
          result.forEach((item) => {
            if (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(searchText.value.toLowerCase()) >= 0 &&
              !item.checked
            ) {
              result2.push(item);
            }
          });
        } else {
          result2 = result
        }
        return result2  ;
      };
    });

    onMounted(init);
    return {
      registrationRequest,
      roles: computed(() => store.getters.roles),
      postRegistrationRequest,
      searchText,
      registrationRequestFilter,
    };
  },
};
</script>

<style>
.center {
  margin: auto;
}
</style>