<template>
  <div class="container pt-5 pb-5">
    <h3>Оставить заявку</h3>

    <div style="max-width: 1000px">
      <div class="mb-3 row">
        <div class="col-sm-7">
          <div class="input-group">
            <label for="category" class="col-sm-3 col-form-label label-class"
              >Организация</label
            >
            <select
              id="category"
              v-model="currentClient"
              class="form-select"
              required
            >
              <option value=""></option>
              <option
                v-for="client in userProfile.clients"
                :key="client.client._id"
                :value="client.client._id"
              >
                {{ client.client.fullname }}
              </option>
            </select>
            <button class="btn btn-warning" @click="currentClient = ''">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
        <div v-if="showPeriod" class="col-sm-1">
          <label for="category" class="col-sm-3 col-form-label label-class"
            >Период</label
          >
        </div>
        <div v-if="showPeriod" class="col-sm-2">
          <v-date-picker v-model="period.from" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="
                  px-2
                  border
                  rounded
                  focus:outline-none focus:border-blue-300
                "
                :value="inputValue"
                v-on="inputEvents"
                style="
                  width: 100%;
                  padding-top: 2px;
                  padding-bottom: 2px;
                  line-height: 2;
                "
              />
            </template>
          </v-date-picker>
        </div>
        <div v-if="showPeriod" class="col-sm-2">
          <v-date-picker v-model="period.to" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="
                  px-2
                  border
                  rounded
                  focus:outline-none focus:border-blue-300
                "
                :value="inputValue"
                v-on="inputEvents"
                style="
                  width: 100%;
                  padding-top: 2px;
                  padding-bottom: 2px;
                  line-height: 2;
                "
              />
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-sm-7">
          <div class="input-group">
            <button class="btn btn-primary" @click="newTask = true">
              Новая заявка
            </button>
            <input
              type="text"
              class="form-control center"
              placeholder="Поиск"
              v-model="searchText"
            />
          </div>
        </div>
      </div>
    </div>

    <task-request
      v-if="newTask"
      v-model:show="newTask"
      :client="currentClient"
    />

    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="query-tab"
          data-bs-toggle="tab"
          data-bs-target="#query"
          type="button"
          role="tab"
          aria-controls="query"
          aria-selected="true"
          @click="showPeriod = false"
        >
          Обращения в работе
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="ready-tab"
          data-bs-toggle="tab"
          data-bs-target="#ready"
          type="button"
          role="tab"
          aria-controls="ready"
          aria-selected="false"
          @click="showPeriod = true"
        >
          История обращений
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <!--Обращения в работе-->
      <div
        class="tab-pane fade show active"
        id="query"
        role="tabpanel"
        aria-labelledby="query-tab"
      >
        <div>
          <table class="table table-striped">
            <thead>
              <th>Дата</th>
              <th>Организация</th>
              <th>№ Заявки</th>
              <th>Статус</th>
              <th>Текст заявки</th>
              <th>Контактное лицо</th>
              <th>Телефон</th>
              <th>Отменить</th>
            </thead>
            <tbody>
              <tr
                v-for="task in tasksFilter(['work', 'query'])"
                :key="task._id"
              >
                <td>
                  <span>{{ dateFormat(task.date) }}</span>
                </td>
                <td>
                  <span>{{ task.client.fullname }}</span>
                </td>
                <td>
                  <span v-if="task.number">{{ task.number }}</span>
                </td>
                <td>
                  <span>{{ task.status.label }}</span>
                </td>
                <td>
                  <span v-if="task.showFulldesc">
                    {{ task.desc }}
                    <button class="btn p-0" @click="task.showFulldesc = false"><font-awesome-icon icon="compress"/></button>
                  </span>
                  <span v-else>
                    {{ task.desc.slice(0, 20) }}
                    <button v-if="task.desc.length >= 20" class="btn p-0" @click="task.showFulldesc = true"><font-awesome-icon icon="expand"/></button>
                  </span>
                </td>
                <td>
                  <span v-if="('contactUser' in task) && (task.contactUser) && ('_id' in task.contactUser) && (task.contactUser._id)">{{ task.contactUser.fullname }} <font-awesome-icon icon="check" /></span>
                  <span v-else>{{ task.contactUserString }}</span>
                </td>
                <td>
                  <span>+7{{ task.contactPhone }}</span>
                </td>
                <td>
                  <button class="btn btn-light" @click="cancelTask(task._id)">
                    <font-awesome-icon icon="times" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--История обращений-->
      <div
        class="tab-pane fade"
        id="ready"
        role="tabpanel"
        aria-labelledby="ready-tab"
      >
        <div>
          <table class="table table-striped">
            <thead>
              <th>Дата</th>
              <th>Организация</th>
              <th>№ Заявки</th>
              <th>Статус</th>
              <th>Текст заявки</th>
              <th>Кол-во часов платных</th>
              <th>Кол-во часов бесплатных</th>
              <th>Контактное лицо</th>
            </thead>
            <tbody>
              <tr
                v-for="task in tasksFilter(['ready', 'cancel'], period)"
                :key="task._id"
              >
                <td>
                  <span>{{ dateFormat(task.date) }}</span>
                </td>
                <td>
                  <span>{{ task.client.fullname }}</span>
                </td>
                <td>
                  <span v-if="task.number">{{ task.number }}</span>
                </td>
                <td>
                  <span>{{ task.status.label }}</span>
                </td>
                <td>
                  <span v-if="task.service">
                    <span v-if="task.showFulldesc">
                      {{ task.service }}
                      <button class="btn p-0" @click="task.showFulldesc = false"><font-awesome-icon icon="compress"/></button>
                    </span>
                    <span v-else>
                      {{ task.service.slice(0, 20) }}
                      <button v-if="task.service.length >= 20" class="btn p-0" @click="task.showFulldesc = true"><font-awesome-icon icon="expand"/></button>
                    </span>                     
                  </span>
                  
                  <span v-else>
                    <span v-if="task.showFulldesc">
                      {{ task.desc }}
                      <button class="btn p-0" @click="task.showFulldesc = false"><font-awesome-icon icon="compress"/></button>
                    </span>
                    <span v-else>
                      {{ task.desc.slice(0, 20) }}
                      <button v-if="task.desc.length >= 20" class="btn p-0" @click="task.showFulldesc = true"><font-awesome-icon icon="expand"/></button>
                    </span>                    
                  </span>

                </td>
                <td>
                  <span>{{ task.serviceQty }}</span>
                </td>
                <td>
                  <span>{{ task.serviceQtyFree }}</span>
                </td>
                <td>
                  <span v-if="('contactUser' in task) && (task.contactUser) && ('_id' in task.contactUser) && (task.contactUser._id)">{{ task.contactUser.fullname }} <font-awesome-icon icon="check" /></span>
                  <span v-else>{{ task.contactUserString }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskRequest from "./TaskRequest.vue";
import { ref } from "vue";
// import { useStore } from "vuex";
import useSupportMixin from "@/components/supportMixin.js";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  name: "SupportTable",
  components: {
    TaskRequest,
  },
  setup() {
    // const store = useStore();
    useSupportMixin();
    const {
      tasks,
      dateFormat,
      searchText,
      tasksFilter,
      currentClient,
      cancelTask,
      period,
      showPeriod,
    } = useSupportMixin();
    const { userProfile } = useCommonMixin();
    const newTask = ref(false);

    return {
      tasks,
      dateFormat,
      newTask,
      searchText,
      tasksFilter,
      currentClient,
      userProfile,
      cancelTask,
      period,
      showPeriod,
    };
  },
};
</script>

<style>
</style>