<template>
  <div class="container pt-2 pb-2">
    <div class="row row-cols-1 pt-2">
      <div class="col-sm-7">
        <div class="input-group">
          <button class="btn btn-primary" @click="addUser">
            Добавить
          </button>
          <input
            type="text"
            class="form-control center"
            placeholder="Поиск"
            v-model="searchText"
            @input="search"
          />
        </div>
      </div>
    </div>

    <div>
      <div
        v-for="(user, userIndex) in usersFilter(client)"
        :key="userIndex"
        class="card lh-1 center mt-3"
        style="max-width: 350px"
      >
        <div class="card-body">
          <form @submit="postUser(user, $event)">
            <div class="row row-cols-2 pt-2">
              <div class="col text-muted"><small>ФИО</small></div>
              <div class="col text-muted"><small>Телефон</small></div>
              <div class="col pt-1">
                <input
                  v-if="user.edit"
                  type="text"
                  class="form-control center"
                  required
                  v-model="user.fullname"
                />
                <span v-else>{{ user.fullname }}</span>
              </div>
              <div class="col pt-1">
                <div class="input-group">
                  <span class="input-group-text p-1" id="basic-addon1">+7</span>
                  <input
                    id="phone"
                    class="form-control p-1"
                    v-model="user.phone"
                    type="tel"
                    pattern="[0-9]{10}"
                    required
                    placeholder="1234567890"
                    :disabled="!user.edit"
                  />
                </div>
              </div>
            </div>

            <div class="row row-cols-2 pt-2">
              <div class="col text-muted"><small>Email</small></div>
              <div class="col text-muted"><small>Email подвержден</small></div>
              <div class="col pt-1">
                <input
                  v-if="user.edit"
                  type="text"
                  class="form-control center"
                  required
                  v-model="user.email"
                />
                <span v-else>{{ user.email }}</span>
              </div>
              <div class="col pt-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="user.active"
                />
              </div>
            </div>

            <div v-if="false" class="row row-cols-2 pt-2">
              <div class="col">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="`flexSwitchPassword-${userIndex}`"
                    v-model="user.setPassword"
                    :disabled="!user._id"
                  />
                  <label
                    class="form-check-label"
                    :for="`flexSwitchPassword-${userIndex}`"
                    ><small>Новый пароль</small></label
                  >
                </div>
              </div>
              <div v-if="user.setPassword" class="col">
                <input
                  class="form-control center"
                  type="text"
                  placeholder="Пароль"
                  required
                  v-model="user.password"
                />
              </div>
            </div>

            <div class="row row-cols-1 pt-2">
              <div class="col">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="`flexSwitchChecked-${userIndex}`"
                    v-model="user.checked"
                  />
                  <label
                    class="form-check-label"
                    :for="`flexSwitchChecked-${userIndex}`"
                    ><small>Активен</small></label
                  >
                </div>
              </div>
            </div>

            <div class="row row-cols-1 pt-2">
              <div class="accordion" :id="`user-roles-accordion-${userIndex}`">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button p-2"
                      type="button"
                      :data-bs-toggle="user._id ? 'collapse' : ''"
                      :data-bs-target="`#user-roles-collapse-${userIndex}`"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Права доступа
                    </button>
                  </h2>
                  <div
                    :id="`user-roles-collapse-${userIndex}`"
                    class="accordion-collapse"
                    :class="{ collapse: user._id }"
                    aria-labelledby="headingOne"
                    :data-bs-parent="`user-roles-accordion-${userIndex}`"
                  >
                    <div class="accordion-body p-2">
                      <button
                        class="btn btn-sm btn-primary"
                        @click="addPermission(user)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Добавить права доступа"
                      >
                        <font-awesome-icon icon="plus" />
                      </button>
                      <div
                        v-for="(role, roleIndex) in user.roles"
                        :key="roleIndex"
                      >
                        <div v-if="!role.delete">
                          <div class="row row-cols-2 pt-3">
                            <div class="col text-muted">
                              <small>Наименование организации</small>
                            </div>
                            <div class="col text-muted"><small>ИНН</small></div>
                            <div class="col pt-1">
                              <span v-if="role.client">
                                {{ role.client.fullname }}
                              </span>
                            </div>
                            <div class="col pt-1">
                              <span v-if="role.client && role.permissionId">
                                {{ role.client.inn }}
                              </span>
                              <input
                                v-if="!role.permissionId"
                                v-model="role.client.inn"
                                class="form-control"
                                required
                                type="text"
                              />
                            </div>
                          </div>

                          <div class="row row-cols-1 pt-0">
                            <div class="col pt-1">
                              <div class="input-group">
                                <select
                                  v-model="role.role"
                                  class="form-select w-75"
                                >
                                  <option value="" selected>
                                    Выберите роль
                                  </option>
                                  <option
                                    v-for="roleItem in roles"
                                    :key="roleItem._id"
                                    :value="roleItem._id"
                                  >
                                    {{ roleItem.label }}
                                  </option>
                                </select>
                                <button
                                  class="btn btn-sm btn-danger"
                                  @click="
                                    removePermission(user, roleIndex)
                                  "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Удалить права доступа"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row row-cols-1 pt-2">
              <div class="col pt-1 btn-group">
                <button
                  type="submit"
                  class="btn btn-light w-100"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Сохранить данные пользователя"
                >
                  <font-awesome-icon icon="save" />
                </button>

                <button
                  @click.prevent="user.edit = user.edit ? false : true"
                  class="btn btn-light w-100"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Редактировать данные пользователя"
                >
                  <font-awesome-icon icon="user-edit" />
                </button>

                <button
                  class="btn btn-light w-100"
                  data-bs-toggle="modal"
                  :data-bs-target="`#remove-user-confirm-${userIndex}`"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Удалить пользователя"
                  type="button"
                >
                  <font-awesome-icon icon="trash" />
                </button>
                <!-- Modal -->
                <div
                  class="modal fade"
                  :id="`remove-user-confirm-${userIndex}`"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Внимание!
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        Вы действительно хотите удалить пользователя "{{ user.fullname }}"?
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Нет
                        </button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="removeUser(user)">
                          Да
                        </button>
                      </div>
                    </div>
                  </div>
                </div>



              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import useUsersMixin from "@/components/usersMixin.js";

export default {
  name: "UsersCard",
  props: {
    client: String
  },

  setup() {
    const store = useStore();
    useUsersMixin();
    const {
      init,
      postUser,
      removePermission,
      addPermission,
      usersFilter,
      addUser,
      users,
      searchText,
      removeUser,
    } = useUsersMixin();

    onMounted(init);
    return {
      users,
      roles: computed(() => store.getters.roles),
      postUser,
      removePermission,
      addPermission,
      usersFilter,
      searchText,
      addUser,
      removeUser,
    };
  },
};
</script>

<style>
</style>