<template>
  <div class="container pt-1 pb-1">
    <div class="row fw-bold">
      <div class="col">Дата</div>
      <div class="col">Автор</div>
      <div class="col">Проверяющий</div>
      <div class="col">Дата проверки</div>
      <div class="col"></div>
    </div>
    <div
      v-for="row in history"
      :key="row._id"
      class="row"
      :class="{
        'text-bg-secondary': !row.dateChecked,
        'text-bg-primary': row.dateChecked,
        'text-bg-success': isCurrent(row._id),
      }"
    >
      <div class="col">{{ dateFormat(row.dateEdit) }}</div>
      <div class="col">{{ row.authorName }}</div>
      <div class="col">{{ row.moderatorName }}</div>
      <div class="col">{{ dateFormat(row.dateChecked) }}</div>
      <div class="col"><button class="btn btn-light" @click="openHistoryVersion(row)">Открыть</button></div>
    </div>
  </div>
</template>

<script>
// import useConfluenceMixin from "@/components/confluenceMixin.js";
import useCommonMixin from "@/components/commonMixin.js";
import { useRouter } from 'vue-router';
// import {watch, onMounted} from 'vue'
// import { useStore } from "vuex";

export default {
  name: "ConfluenceHistory",
  components: {},
  props: { history: { default: [] }, articleId: {default: ''} },
  setup(props) {
    // const { confluenceEditMode, setEditMode, getCategoryById } = useConfluenceMixin();
    // const store = useStore()
    const { dateFormat } = useCommonMixin();
    const router = useRouter()

    const openHistoryVersion = (historyRow) => {
      console.log('openHistoryVersion', props.articleId, historyRow._id);
      router.push({ name: 'confluence', params: { articleId: props.articleId, versionId: historyRow._id } })
    }

    const isCurrent = (versionId) => {
      let result = false
      let currentIndex = props.history.findLastIndex(item =>  item.dateChecked)
      if (currentIndex >= 0) result = props.history[currentIndex]._id == versionId
      return result
    }

    return {
      dateFormat,
      openHistoryVersion,
      isCurrent,
    };
  },
};
</script>

<style scoped></style>
