<template>
  <div
    class="container pt-5 pb-5 d-flex justify-content-center"
    style=""
    id="registration"
  >
    <div class="shadow bg-body rounder p-4">
      <h3 class="text-center mb-3">Регистрация</h3>
      <form @submit="onRegistration" class="">
        <div class="mb-3 row">
          <label for="inn" class="col-sm-3 col-form-label"
            >ИНН Организации</label
          >
          <div class="col-sm-9">
            <input
              id="inn"
              class="form-control"
              v-model="form.inn"
              type="text"
              required
              placeholder=""
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inn" class="col-sm-3 col-form-label"
            >Должность</label
          >
          <div class="col-sm-9">
            <input
              id="inn"
              class="form-control"
              v-model="form.position"
              type="text"
              required
              placeholder=""
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>

        <div class="mb-3 row">
          <label for="fullname" class="col-sm-3 col-form-label">ФИО</label>
          <div class="col-sm-9">
            <input
              id="fullname"
              class="form-control"
              v-model="form.fullname"
              type="text"
              required
              placeholder=""
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>

        <div class="mb-3 row">
          <label for="phone" class="col-sm-3 col-form-label">Телефон</label>
          <div class="col-sm-9">
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">+7</span>
              <input
                id="phone"
                class="form-control"
                v-model="form.phone"
                type="tel"
                pattern="[0-9]{10}"
                required
                placeholder="1234567890"
                @input="status.success = true"
                :disabled="singinReady"
              />
            </div>
          </div>
        </div>

        <div class="mb-3 row">
          <label for="email" class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <input
              id="input-email"
              class="form-control"
              v-model="form.email"
              type="email"
              required
              placeholder=""
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>

        <div class="mb-3 row">
          <label for="password" class="col-sm-3 col-form-label">Пароль</label>
          <div class="col-sm-9">
            <input
              id="password"
              class="form-control"
              v-model="form.password"
              type="password"
              required
              placeholder=""
              minlength="7"
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="password2" class="col-sm-3 col-form-label"
            >Пароль повторно</label
          >
          <div class="col-sm-9">
            <input
              id="password2"
              class="form-control"
              v-model="form.password2"
              type="password"
              required
              placeholder=""
              minlength="7"
              @input="status.success = true"
              :disabled="singinReady"
            />
          </div>
        </div>

        <div class="row">
          <button :disabled="singinReady" type="submit" class="btn btn-light">
            <span v-if="onRegistrationLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Регистрация
          </button>
        </div>

        <div class="row">
          <p v-if="!status.success" class="mt-3 text-danger text-center">
            {{ status.data.data.message }}
            <span v-if="Object.keys(status.data.data.message).length == 0"> Для продолжения регистрации обратитесь к менеджеру по работе с клиентами 8 (8142)55-55-05</span>

          </p>
          <span class="text-center mt-3" v-if="singinReady">
            <p>Для завершения регистрации перейдите по ссылке в письме</p>
            <router-link
              class="nav-link active btn btn-primary"
              type="button"
              aria-current="page"
              :to="{ name: 'login' }"
              >Войти</router-link
            >
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/*eslint-disable no-unused-vars*/
import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Singup",
  components: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const form = ref({
      company: "",
      address: "",
      password: "",
      password2: "",
      email: "",
      phone: "",
      inn: "",
      position: "",
    });
    const status = ref({
      success: true,
      data: {data: {message: ""}},
    });
    const singinReady = ref(false);

    const onRegistrationLoading = ref(false)
    const onRegistration = async (evt) => {
      onRegistrationLoading.value = true
      evt.preventDefault();
      if (form.value.password != form.value.password2) {
        status.value.success = false;
        status.value.data.data.message = "Пароли не совпадают";
      } else {
        status.value = await store.dispatch("singup", form.value);
        console.log('onRegistration status', status.value);
        if (status.value.success) {
          singinReady.value = true;
          console.log("Успешно зарегались");
        }
      }
      await nextTick();
      window.scrollTo(0, document.body.scrollHeight);
      onRegistrationLoading.value = false
    };

    return {
      status,
      store,
      form,
      singinReady,
      onRegistration,
    };
  },
};
</script>

<style scoped>
</style>

