<template>
  <div>
    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="dogovor-tab"
          data-bs-toggle="tab"
          data-bs-target="#dogovor"
          type="button"
          role="tab"
          aria-controls="dogovor"
          aria-selected="true"
          @click="showPeriod = false"
        >
          Активные договоры и сервисы 1C
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="fnOfd-tab"
          data-bs-toggle="tab"
          data-bs-target="#fnOfd"
          type="button"
          role="tab"
          aria-controls="fnOfd"
          aria-selected="false"
          @click="showPeriod = true"
        >
          ФН/ОФД
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <!--Активные договоры-->
      <div
        class="tab-pane fade show active"
        id="dogovor"
        role="tabpanel"
        aria-labelledby="dogovor-tab"
      >
        <div class="mt-2">
          <div class="row mb-2 fw-bold">
            <div class="col-sm-3">Наименование</div>
            <div class="col-sm">Статус</div>
          </div>
          <div
            class="row mb-2"
            v-for="service in serviceIts"
            :key="service._id"
          >
            <div class="col-sm-3">{{ service.label }}</div>

            <div class="col-sm">
              <button
                v-if="service.expired < 100"
                class="btn text-white"
                type="button"
                :class="{
                  'bg-warning': service.mark,
                  'bg-success': !service.mark,
                }"
                style="width: 120px"
              >
                Активен
              </button>
              <span v-if="service.mark && service.expired < 100" class="mx-3"
                >Заканчивается срок действия</span
              >
              <button
                v-if="service.expired >= 100"
                class="btn btn-danger"
                type="button"
                style="width: 120px"
                @click="connectToService(service.label)"
                :disabled="!client"
              >
                Подключить
              </button>

              <span v-if="service.expired >= 100" class="mx-3"
                >Закончился срок действия</span
              >
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div
            class="row mb-2"
            v-for="service in serviceOther"
            :key="service._id"
          >
            <div class="col-sm-3">{{ service.label }}</div>
            <div class="col-sm">
              <button
                v-if="service.expired < 100"
                class="btn text-white"
                type="button"
                :class="{
                  'bg-warning': service.mark,
                  'bg-success': !service.mark,
                }"
                style="width: 120px"
              >
                Активен
              </button>
              <span v-if="service.mark && service.expired < 100" class="mx-3"
                >Заканчивается срок действия</span
              >

              <button
                v-if="service.expired >= 100"
                class="btn btn-danger"
                type="button"
                style="width: 120px"
                @click="connectToService(service.label)"
                :disabled="!client"
              >
                Подключить
              </button>
            </div>
          </div>

          <div
            class="row mb-2"
            v-for="(service, index) in serviceToConnect"
            :key="index"
          >
            <div class="col-sm-3">{{ service }}</div>
            <div class="col-sm-3">
              <button
                class="btn btn-primary"
                type="button"
                @click="connectToService(service)"
                :disabled="!client"
                style="width: 120px"
              >
                Подключить
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--ФН/ОФД-->
      <div
        class="tab-pane fade"
        id="fnOfd"
        role="tabpanel"
        aria-labelledby="fnOfd-tab"
      >
        <div class="mt-2 text-center">
          <div class="row mb-2 fw-bold">
            <div class="col-sm-2">Модель ККМ</div>
            <div class="col-sm-2">Заводской номер ККМ</div>
            <div class="col-sm-2">Номер ФН</div>
            <div class="col-sm">Срок действия ФН</div>
            <div class="col-sm">Срок действия ОФД</div>
          </div>
          <div
            class="row mb-2"
            v-for="service in serviceFnOfd"
            :key="service._id"
          >
            <div class="col-sm-2">{{ service.kkmModel }}</div>
            <div class="col-sm-2">{{ service.kkmNumber }}</div>
            <div class="col-sm-2">{{ service.fnNumber }}</div>
            <div class="col-sm">
              <span v-if="service.fn">
                {{ new Date(service.fnDateTo).toLocaleString().slice(0, 10) }}
              </span>
            </div>
            <div class="col-sm">
              <button
                v-if="service.mark && service.ofd"
                class="btn btn-warning"
              >
                {{ new Date(service.ofdDateTo).toLocaleString().slice(0, 10) }}
              </button>
              <span v-if="!service.mark && service.ofd">{{
                new Date(service.ofdDateTo).toLocaleString().slice(0, 10)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCommonMixin from "@/components/commonMixin.js";
import { computed, ref, watch } from "vue";
import { inject } from "vue";
import { useStore } from "vuex";

export default {
  components: {},

  props: {
    client: { default: "" },
  },

  setup(props) {
    const warningBus = inject("warningBus");
    const store = useStore();

    const { userProfile } = useCommonMixin();
    console.log(props.client);
    const services = ref([]);

    const serviceIts = computed(() => {
      let result = services.value.filter(
        (item) => item.its && item.expired <= 100
      );
      if (result.length == 0) {
        let expiredItsServices = services.value.filter(
          (item) => item.its && item.expired > 100
        );
        result = expiredItsServices.sort((a, b) => {
          return a.expired - b.expired;
        });
        result.splice(1, result.length);
      }
      return result;
    });

    const serviceOther = computed(() => {
      return services.value.filter(
        (item) => !item.its && !item.fn && !item.ofd && item.expired <= 100
      );
    });

    const serviceFnOfd = computed(() => {
      let result = services.value.filter(
        (item) => (item.fn || item.ofd) && item.active
      );
      result.sort((a, b) => {
        if (a.fnDateTo < b.fnDateTo) return -1;
        if (a.fnDateTo > b.fnDateTo) return 1;
        return 0;
      });

      return result;
    });

    const leadTypes = [
      {name: 'Отчетность', type: 'report'},
      {name: '1С:Кабинет сотрудника', type: 'kabinet'},
      {name: '1С-ЭДО', type: 'edo'},
      
    ]
    const serviceToConnect = computed(() => {
      const newServices = [
        "1СпаркРиски",
        "1С:Кабинет сотрудника",
        "1С-Контрагент",
        "Отчетность",
      ];
      let offerService = [];
      newServices.forEach((service) => {
        if (
          services.value.findIndex((item) => item.label.includes(service)) < 0
        ) {
          offerService.push(service);
        }
      });
      return offerService;
    });

    const connectToService = (service) => {
      let typeIndex = leadTypes.findIndex(item => item.name == service)
      let leadType = typeIndex >= 0 ? leadTypes[typeIndex].type : ''

      let taskRequest = {
        client: props.client,
        desc: `Подключение к сервису ${service}`,
        contactPhone: userProfile.value.phone.slice(2, 12),
        contactUser: userProfile.value._id,
        lead: true,
        leadType: leadType, 
      };

      let msgSuccess = [
        `Заявка на подключение к сервису ${service} отправлена.`,
        "Ожидайте, с Вами свяжется наш специалист.",
      ];
      postTask(taskRequest, msgSuccess);
      console.log("Conncet to service", service);
    };

    const postTask = async (form, msgSuccess) => {
      let responseTask = await store.dispatch("POSTtask", form);
      if (responseTask.status) {
        warningBus.emit("show", msgSuccess);
      } else {
        warningBus.emit("show", [
          "Ошибка отправки заявки. Попробуйте позже.",
          responseTask.data.data,
        ]);
      }
    };

    const getClientService = async () => {
      const response = await store.dispatch("FETCHclientService", props.client);
      if (response.status) {
        services.value = response.data;
      } else {
        services.value = [];
      }

      let servicesAll = serviceIts.value.concat(serviceOther.value).concat(serviceFnOfd.value)

      let notifyMsgs = [];
      servicesAll.forEach((service) => {
        let msg = "";
        if (
          service.notify &&
          (service.expired <= 100 ||
            serviceIts.value.findIndex((item) => item._id == service._id) >= 0)
        ) {
          let serviceType = service.its ? "договора" : "сервиса";
          msg = `Срок действия ${serviceType} '${service.label}' заканчивается, свяжитесь с менеджером для продления.`;
          notifyMsgs.push(msg);
        }

        if (service.notifyFn) {
          msg = `Срок действия ФН '${service.kkmModel} (${service.kkmNumber})' заканчивается, свяжитесь с менеджером для продления.`;
          notifyMsgs.push(msg);
        }

        if (service.notifyOfd) {
          msg = `Срок действия ОФД '${service.kkmModel} (${service.kkmNumber})' заканчивается, свяжитесь с менеджером для продления.`;
          notifyMsgs.push(msg);
        }
      });
      if (notifyMsgs.length > 0) {
        warningBus.emit("show", notifyMsgs);
      }
    };

    watch(
      () => props.client,
      () => {
        getClientService();
      }
    );

    return {
      userProfile,
      serviceIts,
      serviceOther,
      serviceToConnect,
      connectToService,
      getClientService,
      serviceFnOfd,
      services,
    };
  },
};
</script>

<style>
.row {
  display: flex;
  align-items: center;
}
div.progress {
  margin: 0;
}
</style>