<template>
  <div class="container pt-2 pb-2">
    <input
      type="text"
      class="form-control w-25"
      placeholder="Поиск"
      v-model="searchText"
      @input="search"
    />
    <table class="table table-striped">
      <thead>
        <th>ФИО</th>
        <th>Телефон</th>
        <th>Email</th>
        <th>Email подтвержден</th>
        <th>Наименование организации</th>
        <th>ИНН</th>
        <th>Должность</th>
        <th>Роль</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="req in registrationRequestFilter(client)" :key="req._id">
          <td>
            <span v-if="req.user">{{ req.user.fullname }}</span>
          </td>
          <td>
            <span v-if="req.user">{{ req.user.phone }}</span>
          </td>
          <td>
            <span v-if="req.user">{{ req.user.email }}</span>
          </td>
          <td>
            <input
              v-if="req.user"
              class="form-check-input"
              type="checkbox"
              v-model="req.user.active"
              checked
              disabled
            />
          </td>
          <td>
            <span v-if="req.client"> {{ req.client.fullname }} </span>
          </td>
          <td>
            <span v-if="req.inn">{{ req.inn }}</span>
          </td>
          <td>
            <span v-if="req.position">{{ req.position }}</span>
          </td>
          <td style="min-width: 150px">
            <select v-if="!req.checked" v-model="req.role" class="form-select">
              <option value="" selected>Выберите роль</option>
              <option v-for="role in roles" :key="role._id" :value="role._id">
                {{ role.label }}
              </option>
            </select>
          </td>
          <td>
            <button
              :disabled="req.checked || !req.role"
              @click="postRegistrationRequest(req)"
              class="btn btn-primary w-100"
            >
              <span v-if="req.checked"> Подтвержден </span>
              <span v-if="!req.checked"> Подтвердить </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  name: "RegistrationRequestTable",
  props: { client: String },
  setup() {
    const store = useStore();
    const registrationRequest = ref([]);
    const searchText = ref("");
    const { userProfile } = useCommonMixin();


    const init = async () => {
      let response = await store.dispatch("FETCHregistrationRequest");
      registrationRequest.value = response.status ? response.data : [];
      await store.dispatch("FETCHroles");
    };

    const postRegistrationRequest = async (req) => {
      
      if (!req.contactExists && userProfile.value.isAdminCs) {
        let confirm = window.confirm(
          `Внимание! ${req.user.fullname} - данного контактного лица нет в Контрагенте. Свяжитесь с администратором Контрагента и уточните актуальность нового контактного лица. Принять запрос на регистрацию?`
        );
        if (!confirm) {
          return;
        }
        
      }
      
      let response = await store.dispatch("POSTregistrationRequest", req);
      if (response.status) {
        req.checked = true;
      }
      console.log(response);
      window.location.reload();
    };

    const registrationRequestFilter = computed(() => {
      return (client) => {
        let result = [];
        if (client != "") {
          registrationRequest.value.forEach((item) => {
            if (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(client.toLowerCase()) >= 0 &&
              !item.checked
            ) {
              result.push(item);
            }
          });
        } else {
          result = registrationRequest.value.filter((item) => !item.checked);
        }
        let result2 = []
        if (searchText.value.length >= 2) {
          console.log(registrationRequest.value);
          result.forEach((item) => {
            if (
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(searchText.value.toLowerCase()) >= 0 &&
              !item.checked
            ) {
              result2.push(item);
            }
          });
        } else {
          result2 = result
        }
        return result2  ;

      };
    });

    onMounted(init);
    return {
      registrationRequest,
      roles: computed(() => store.getters.roles),
      postRegistrationRequest,
      searchText,
      registrationRequestFilter,
    };
  },
};
</script>

<style>
.center {
  margin: auto;
}
</style>