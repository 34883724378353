import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import VCalendar from 'v-calendar';

import "medium-zoom"
import mitt from 'mitt';                  // Import mitt
const warningBus = mitt();                   // Initialize mitt

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faCompress } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faChevronUp, faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";

library.add(faPhone);
library.add(faSave);
library.add(faUserEdit);
library.add(faPlus);
library.add(faTrash);
library.add(faTimes);
library.add(faCheck);
library.add(faExpand);
library.add(faCompress);
library.add(faEdit);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faSearch);

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import ruRu from '@kangc/v-md-editor/lib/lang/ru-RU';

import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

VueMarkdownEditor.lang.use('ru-Ru', ruRu);

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .provide('warningBus', warningBus)
    .use(store)
    .use(router)
    .use(VCalendar, {})
    .use(VueMarkdownEditor)
    .mount('#app')
