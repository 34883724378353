<template>
  <div>
    <div class=" pb-0" ref="top-panel">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid container">
          <a class="navbar-brand" href="https://pcs.ru" target="_blank">
            <img
              class="img-fluid"
              :width="logoWidth"
              src="@/assets/logo.png"
            />
          </a>

          <div class="col py-0 px-0 logo-text" style="max-width: 250px; min-width: 200px;">
            <div class="row py-0 px-0">
              <span>Автоматизируем бизнес с 1997 года</span> 
            </div>
            <div class="row py-0">
              <span>Центр сопровождения 1С</span> 
            </div>
            <div class="row py-0">
              <span>Ранее Компьютер Сервис</span>
            </div>
        </div>



          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto">
              <li v-if="userName" class="nav-item">
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  :to="{ name: 'home' }"
                  >Главная</router-link
                >
              </li>
              <li
                v-if="userProfile.isAdmin && userProfile.checked"
                class="nav-item"
              >
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  :to="{ name: 'users' }"
                  >Пользователи</router-link
                >
              </li>
              <li v-if="userProfile.checked" class="nav-item">
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  :to="{ name: 'support' }"
                  >Оставить заявку</router-link
                >
              </li>
              <li v-if="userProfile.checked" class="nav-item">
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  :to="{ name: 'confluence' }"
                  >База знаний</router-link
                >
              </li>
            </ul>

            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a
                  href="mailto:zakaz@pcs.ru"
                  class="nav-link active btn fw-bold "
                  style="border-color: white"
                  type="button"
                  aria-current="page"
                  >zakaz@pcs.ru</a>
              </li>
              <li class="nav-item">
                <a
                  href="tel:+78142555505"
                  class="nav-link active btn fw-bold"
                  style="border-color: white"
                  type="button"
                  aria-current="page"
                  >+7(8142)55-55-05</a>
              </li>

              <li v-if="!userName" class="nav-item">
                <router-link
                  class="nav-link active btn fw-bold confluence-main-category"
                  style="border-color: whitel"
                  type="button"
                  aria-current="page"
                  :to="{ name: 'login' }"
                  >Вход</router-link
                >
              </li>
              <li v-if="userName" class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ userName }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link
                      class="nav-link active px-1"
                      aria-current="page"
                      :to="{ name: 'profile' }"
                      >Профиль</router-link
                    >
                  </li>
                  <li>
                    <a class="dropdown-item btn px-1" @click="userExit"
                      >Выход</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    <div v-if="isPasswordTemp" class="shadow bg-body rounder p-4">
      <h4>
        <p>Вам выдан временный пароль.</p>
        <p>
          Необходимо
          <router-link :to="{ name: 'profile' }">перейти в профиль</router-link>
          и сменить его.
        </p>
      </h4>
    </div>

    <div
      v-if="userProfile._id && !userProfile.checked"
      class="shadow bg-body rounder p-4"
    >
      <h4>
        <p>Добро пожаловать в личный кабинет компании ПРОФКЕЙС!</p>
        <p>Вы может решить любой вопрос по 1С, оставив заявку в поддержку.</p>
        <p>Для этого:</p>
        <ol>
          <li>
            Запросите права доступа у Администратора вашей компании.
          </li>
          <li>В разделе "Оставить заявку" нажмите Новая заявка.</li>
        </ol>
      </h4>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/*eslint-disable no-unused-vars*/
import { useStore } from "vuex";
import { computed, onMounted, onUpdated } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import * as bootstrap from "bootstrap";

export default {
  name: "TopPanel",
  components: {},
  setup(props) {
    const store = useStore();
    useCommonMixin();
    const { userProfile, isPasswordTemp, isMobile } = useCommonMixin();

    const userExit = () => {
      store.commit("removeToken");
      document.location.reload(true);
    };

    const publicPath = () => {
      return process.env.NODE_ENV === "production" ? "/lk/" : "/";
    };

    const init = () => {
      navLinksToggle();
    };

    const logoWidth = computed(() => {
      return isMobile.value ? 130 : 130
    })

    const navLinksToggle = () => {
      const navLinks = document.querySelectorAll(".nav-item");
      const menuToggle = document.getElementById("navbarNav");
      const bsCollapse = new bootstrap.Collapse(menuToggle);
      console.log("navLinks", navLinks);
      navLinks.forEach((l) => {
        l.addEventListener("click", () => {
          bsCollapse.toggle();
        });
      });
    };

    // onUpdated(navLinksToggle);

    return {
      userExit,
      userName: computed(() => store.getters.userName),
      jwtAccessValid: computed(() => store.getters.jwtAccessValid),
      publicPath,
      userProfile,
      isPasswordTemp,
      isMobile,
      logoWidth,
    };
  },
};
</script>

<style scoped>
.modal-title,
.modal-body {
  text-align: center !important;
}

#top-panel {
  1height: 56px;
}

.logo-text {
  font-size: 12px;
}
</style>

