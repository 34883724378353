<template>
  <div class="container">
    <div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <font-awesome-icon icon="search"
        /></span>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск"
          aria-label="Поиск"
          aria-describedby="basic-addon1"
          v-model="searchText"
          @keyup.enter="searchConfluenceArticle"
        />
        <button class="btn" @click="searchConfluenceArticle">Найти</button>
        <button class="btn" @click="clearSearchText">Очистить</button>
      </div>

      <Spinner v-if="searchArticleSpinner" />
      <div v-for="article in articlesFilted" :key="article._id">
        <div>
          <h3>
            <router-link
              :to="{ name: 'confluence', params: { articleId: article._id } }"
            >
              {{ article.label }}
            </router-link>
          </h3>
          <p>
            <v-md-editor
              v-model="article.fullSearchText"
              mode="preview"
            ></v-md-editor>

            <span v-if="false"
              >{{ article.prefixText }}{{ article.searchText
              }}{{ article.suffixText }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import useConfluenceMixin from "@/components/confluenceMixin.js";
// import useCommonMixin from "@/components/commonMixin.js";
import Spinner from "@/components/Spinner.vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ConfluenceSearch",
  components: { Spinner },
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    // const router = useRouter();

    const searchText = ref("");
    const articlesFilted = ref([]);
    const searchArticleSpinner = ref(false);

    const { confluenceCurrentCategory } = useConfluenceMixin();

    // const { dateFormat, scrollToId, isModerator } = useCommonMixin();

    const searchConfluenceArticle = async () => {
      articlesFilted.value = [];
      if (searchText.value.length < 2) return;
      searchArticleSpinner.value = true;
      const response = await store.dispatch(
        "SEARCHconfluenceArticle",
        searchText.value
      );

      if (response.status) {
        articlesFilted.value = response.data;
      }
      searchArticleSpinner.value = false;
    };

    const clearSearchText = () => {
      searchText.value = "";
      articlesFilted.value = [];
    };

    watch(
      () => searchText.value,
      () => {
        // console.log("watch searchText", searchText);
        emit("onSearchText", searchText.value);
      }
    );

    watch(
      () => articlesFilted.value,
      () => {
        // console.log("watch articlesFilted", articlesFilted.value.length);
        emit("onArticlesFiltedChange", articlesFilted.value.length);
      }
    );

    watch(confluenceCurrentCategory, () => {
      clearSearchText()
    })

    onMounted(() => {});

    return {
      searchText,
      articlesFilted,
      searchConfluenceArticle,
      searchArticleSpinner,
      clearSearchText,
    };
  },
};
</script>

<style></style>
