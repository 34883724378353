<template>
  <div class="w-100 doc-request-bg-color px-3 pt-3" id="doc-request" style="max-width: 700px ">
    <h4 class="mb-3">Запрос закрывающих документов</h4>
    <form @submit="onSendRequest($event)" class="form">
      <div class="mb-3 row " >
        <label for="request-types" class="col-sm-3 col-form-label label-class"
          >Тип запроса</label
        >
        <div class="col-sm label-class">
          <select
            id="request-type"
            v-model="currentRequestType"
            class="form-select"
            required
            @change="form.edo = ''"
          >
            <option v-for="type in requestTypes" :key="type" :value="type">
              {{ type }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="request-org" class="col-sm-3 col-form-label label-class"
          >Организация ПРОФКЕЙС</label
        >
        <div class="col-sm label-class">
          <select
            id="request-org"
            v-model="currentRequestOrg"
            class="form-select"
            required
            @change="form.edo = ''"
          >
            <option v-for="org in requestOrgs" :key="org.inn" :value="org.inn">
              {{ org.label }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="mb-3 row"
        v-if="
          currentRequestType == 'Акт сверки' ||
          currentRequestType == 'Расшифровка работ'
        "
      >
        <div class="col-sm-3">
          <label class="col-sm-3 col-form-label label-class">Период</label>
        </div>

        <div class="col-sm-3">
          <input type="date" class="form-control" v-model="form.period.from" @change="form.period.to = ''" required>
        </div>
        <div class="col-sm-3">
          <input type="date" class="form-control" v-model="form.period.to" required>
        </div>
      </div>

      <div
        class="mb-3 row row-cols-2"
        v-if="currentRequestType == 'Закрывающие документы'"
      >
        <div class="col-sm-3">
          <label class="col-sm-3 col-form-label label-class"
            >Способ получения документов</label
          >
        </div>
        <div class="col-sm-2">
          <input
            type="radio"
            :value="true"
            v-model="form.edo"
            class="btn-check"
            name="edo"
            id="edo-true"
            autocomplete="off"
            :disabled="!orgAcceptEdo"
          />
          <label class="btn btn-outline-primary" for="edo-true">ЭДО</label>
        </div>
        <div class="col px-0">
          <input
            type="radio"
            :value="false"
            v-model="form.edo"
            class="btn-check"
            name="edo"
            id="edo-false"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="edo-false">Email</label>
        </div>
      </div>

      <div class="mb-3 row" v-if="!form.edo">
        <label for="desc" class="col-sm-3 col-form-label label-class"
          >Email</label
        >
        <div class="col-sm-9 label-class">
          <input
            id="email"
            class="form-control"
            required
            placeholder=""
            requered
            v-model="form.email"
          />
        </div>
      </div>

      <div
        class="mb-3 row"
        v-if="currentRequestType == 'Закрывающие документы'"
      >
        <label for="desc" class="col-sm-3 col-form-label label-class">
          По счету №
        </label>
        <div class="col-sm-9">
          <div v-for="(doc, index) in form.docs" :key="index" class="row mb-2">
            <div class="col-sm-4 label-class">
              <input
                id="doc-number"
                class="form-control"
                required
                placeholder="Номер"
                v-model="doc.number"
              />
            </div>

            <div class="col-sm-4 label-class">
              <input type="date" class="form-control" v-model="doc.date">
              <v-date-picker v-if="false" v-model="doc.date" mode="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="
                      px-2
                      border
                      rounded
                      focus:outline-none focus:border-blue-300
                    "
                    :value="inputValue"
                    v-on="inputEvents"
                    style="
                      width: 100%;
                      padding-top: 2px;
                      padding-bottom: 2px;
                      line-height: 2;
                    "
                    placeholder="Дата"
                  />
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <button type="submit" class="btn btn-primary" :disabled="(!client || (currentRequestType != 'Закрывающие документы' && ( !form.period.to || !form.period.from)))">
          <span
            v-if="false"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="currentRequestType != 'Закрывающие документы'">
            <span v-if="!client && form.period.to && form.period.from"> Выберите вашу организацию</span>
            <span v-if="!client && (!form.period.to || !form.period.from)"> Выберите вашу организацию и период</span>
            <span v-if="client && (!form.period.to || !form.period.from)">Выберите период </span>
            <span v-if="client && form.period.to && form.period.from"> Отправить запрос </span>          
          </span>
          <span v-if="currentRequestType == 'Закрывающие документы'">
            <span v-if="!client"> Выберите вашу организацию</span>
            <span v-if="client"> Отправить запрос </span>  
          </span>

        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useCommonMixin from "@/components/commonMixin.js";
import { computed, onMounted, ref, watch } from "vue";
import { inject } from "vue";
import { useStore } from "vuex";

export default {
  components: {},

  props: {
    client: { default: "" },
  },

  setup(props) {
    const warningBus = inject("warningBus");
    const store = useStore();

    const { userProfile } = useCommonMixin();
    const currentRequestType = ref("Акт сверки");
    const currentRequestOrg = ref("100100219053");

    const months = ref([
      {name: 'Январь', num: 1},
      {name: 'Февраль', num: 2},
      {name: 'Март', num: 3},
      {name: 'Апрель', num: 4},
      {name: 'Май', num: 5},
      {name: 'Июнь', num: 6},
      {name: 'Июль', num: 7},
      {name: 'Август', num: 8},
      {name: 'Сентябрь', num: 9},
      {name: 'Октябрь', num: 10},
      {name: 'Ноябрь', num: 11},
      {name: 'Декабрь', num: 12},
    ])

    const monthsTo = computed(() => {
      let result = months.value
      if (form.value.period.from) {
        result = months.value.filter(month => month.num >= form.value.period.from.num)
      }
      return result
    })

    const requestTypes = [
      "Акт сверки",
      "Закрывающие документы",
      "Расшифровка работ",
    ];

    const requestOrgs = [
      {
        label: "ИП Макаров Илья Владимирович",
        inn: "100100219053",
      },
      {
        label: "ИП Мишков Вадим Борисович",
        inn: "100110705285",
      },
      {
        label: "Фирма Компьютер Сервис",
        inn: "1001141729",
      },
    ];

    const form = ref({
      period: {
        from: "",
        to: "",
      },
      edo: "",
      email: "",
      docs: [
        {
          number: "",
          date: "",
          type: "",
        },
      ],
    });

    const docTypes = ref(["Акт", "Счет"]);

    const addDoc = () => {
      form.value.docs.push({
        number: "",
        date: "",
        type: "",
      });
    };

    const removeDoc = (index) => {
      if (form.value.docs.length >= 2) {
        form.value.docs.splice(index, 1);
      }
    };

    const orgAcceptEdo = computed(() => {
      let result = false;
      let clientIndex = userProfile.value.clients.findIndex(
        (item) => item.client._id == props.client
      );
      if (clientIndex >= 0) {
        let clientEdoOrg = userProfile.value.clients[clientIndex].client.edoOrg;
        result =
          clientEdoOrg.findIndex((item) => item == currentRequestOrg.value) >= 0
            ? true
            : false;
      }
      return result;
    });

    const onSendRequest = (event) => {
      event.preventDefault();
      let desc = "";
      let orgIndex = requestOrgs.findIndex(
        (item) => item.inn == currentRequestOrg.value
      );
      let org = orgIndex >= 0 ? requestOrgs[orgIndex].label : "Не выбрана";

      if (currentRequestType.value == "Акт сверки") {
        let dateFrom = form.value.period.from;
        let dateTo = form.value.period.to
        desc = `Запрос акта сверки по ${org} за период: ${dateFrom} - ${dateTo}.\n Отправить на почту ${form.value.email}.`;
      } else if (currentRequestType.value == "Закрывающие документы") {
        desc = `Запрос закрывающих документов по ${org} `;
        desc = form.value.edo
          ? desc + "по ЭДО."
          : desc + `на почту ${form.value.email}.\n`;
        form.value.docs.forEach((doc) => {
          // let date = new Date(doc.date);
          // date = date.toLocaleString().slice(0, 10);
          let date = doc.date
          desc = desc + ` ${doc.type} № ${doc.number} от ${date}.\n`;
        });
      } else if (currentRequestType.value == "Расшифровка работ") {
        let dateFrom = form.value.period.from.name;
        let dateTo = form.value.period.to.name
        desc = `Запрос расшифровки работ по ${org} за период: ${dateFrom} - ${dateTo}.\n Отправить на почту ${form.value.email}.`;
      }

      let taskRequest = {
        client: props.client,
        desc: desc,
        contactPhone: userProfile.value.phone.slice(2, 12),
        contactUser: userProfile.value._id,
        lead: true,
        leadType: 'closeDoc',
      };

      let msgSuccess = [
        `Запрос закрывающих документов отправлен.`,
        "Ожидайте, с Вами свяжется наш специалист.",
      ];
      postTask(taskRequest, msgSuccess);
    };

    const postTask = async (form, msgSuccess) => {
      let responseTask = await store.dispatch("POSTtask", form);
      if (responseTask.status) {
        warningBus.emit("show", msgSuccess);
      } else {
        warningBus.emit("show", [
          "Ошибка отправки заявки. Попробуйте позже.",
          responseTask.data.data,
        ]);
      }
    };

    watch(
      () => props.client,
      () => {
        //getClientService();
      }
    );

    const init = () => {
      form.value.email = userProfile.value.email;
    };

    onMounted(init);

    return {
      userProfile,
      requestTypes,
      requestOrgs,
      form,
      currentRequestType,
      currentRequestOrg,
      docTypes,
      addDoc,
      removeDoc,
      orgAcceptEdo,
      onSendRequest,
      months,
      monthsTo,
    };
  },
};
</script>

<style scoped>
.doc-request-bg-color {
  background-color: #00000012;
}
</style>