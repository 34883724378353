<template>
  <div class="container pt-5 pb-5">
    <h3 class="text-left">База знаний</h3>
    <div v-if="userProfile.isAdminCs || userProfile.isStaffCs">
      <button
        v-if="!confluenceEditMode"
        class="btn btn-warning"
        @click="setEditMode(true)"
      >
        Редактировать
      </button>
      <button
        v-if="confluenceEditMode"
        class="btn btn-primary"
        @click="setEditMode(false)"
      >
        Просмотр
      </button>
    
    </div>

    <div>
      <button
        v-if="confluenceEditMode"
        class="btn btn-success mt-2"
        @click="showRequestList()"
      >
        На проверке
      </button>
    </div>

    
    <div class="row align-items-start mt-2">
      <div class="col-sm" style="max-width: 350px">
        <confluence-categories @click="requestListVisible=false"/>
      </div>
      
      <div class="col">
        <confluence-search v-if="searchVisible && !confluenceEditMode" @onSearchText="onSearchText" @onArticlesFiltedChange="onArticlesFiltedChange"/>
        <confluence-article v-show="!requestListVisible && !searchActive" :versionId="versionId" />
        <confluence-request-list v-if="requestListVisible && !searchActive" @onOpen="handleOpenArticle()"/>
        
      </div>
    </div>
  </div>
</template>

<script>
import ConfluenceCategories from "./ConfluenceCategories.vue";
import ConfluenceArticle from "./ConfluenceArticle.vue";
import ConfluenceRequestList from "./ConfluenceRequestList.vue"
import ConfluenceSearch from './ConfluenceSearch.vue'

import useConfluenceMixin from "@/components/confluenceMixin.js";
import useCommonMixin from "@/components/commonMixin.js";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Confluence",
  components: { ConfluenceCategories, ConfluenceArticle, ConfluenceRequestList, ConfluenceSearch },
  props: { articleId: { default: "" }, versionId: { default: "" } },
  setup(props) {
    const { confluenceEditMode, setEditMode, getCategoryById } =
      useConfluenceMixin();
    useConfluenceMixin();

    const { userProfile } = useCommonMixin();

    const store = useStore();
    const router = useRouter();

    const requestListVisible = ref(false)
    const searchVisible = ref(true)
    const searchActive  = ref(false)
    watch(
      () => props.articleId,
      () => {
        setCurrentCategoryById(props.articleId);
      }
    );

    const setCurrentCategoryById = (articleId) => {
      //console.log('articleId', articleId);
      if (articleId) {
        let categoryObj = getCategoryById(articleId);
        //console.log('categoryObj', categoryObj);
        if (categoryObj)
          store.commit("SETConfluenceCurrentCategory", categoryObj);
      }
    };

    const showRequestList = () => {
      router.push({name: 'confluence'})
      requestListVisible.value = requestListVisible.value ? false : true
    }

    const handleOpenArticle = () => {
      requestListVisible.value = false
    }

    const onSearchText = (searchText) => {
      console.log('onSearchText', searchText);
      // searchVisible.value = searchText && searchText.length >= 1
    }

    const onArticlesFiltedChange = (articlesFiltedLength) => {
      console.log('onArticlesFiltedChange', articlesFiltedLength);
      searchActive.value = articlesFiltedLength > 0
    }


    onMounted(async () => {
      await store.dispatch("FETCHconfluenceCategories");
      //console.log('props.articleId', props.articleId);
      setCurrentCategoryById(props.articleId);
    });

    return {
      confluenceEditMode,
      setEditMode,
      userProfile,
      showRequestList,
      requestListVisible,
      handleOpenArticle,
      searchVisible,
      onSearchText,
      onArticlesFiltedChange,
      searchActive,
    };
  },
};
</script>

<style scoped></style>
