import { computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex';
import { ref } from "vue";
import { inject } from "vue";
import * as bootstrap from "bootstrap";

export default () => {
  const store = useStore()
  const warningBus = inject("warningBus")
  const taskCategories = computed(() => store.getters.taskCategories)
  const tasks = computed(() => store.getters.tasks)
  const dateFormat = (date) => {
    let dateFormated = new Date(date)
    return dateFormated.toLocaleString()
  }
  const currentClient = ref('')
  const searchText = ref('')
  const period = ref({
    from: '',
    to: ''
  })
  const showPeriod = ref(false)
  const intervalId = ref("");

  const tasksFilter = computed(() => {
    return (status, period) => {
      let result = [];
      const inPeriod = (date) => {
        let dateInPeriod = true
        if (period && period.from) {
          let from = (new Date(date) >= period.from.setHours(0, 0, 0, 0))
          dateInPeriod = dateInPeriod && from
        }
        if (period && period.to) {
          let to = (new Date(date) <= period.to.setHours(23, 59, 59, 999))
          dateInPeriod = dateInPeriod && to
        }
        return dateInPeriod
      }


      if (searchText.value.length >= 2) {
        result = tasks.value.filter(task => {
          let isCurrentClient = (currentClient.value) ? task.client._id == currentClient.value : true
          let statusMatch = status.includes(task.status.name)
          let searchIndex = JSON.stringify(task).toLowerCase().indexOf(searchText.value.toLowerCase()) >= 0
          return isCurrentClient && searchIndex && statusMatch && inPeriod(task.date)
        });
      } else {
        result = tasks.value.filter(task => {
          let isCurrentClient = (currentClient.value) ? task.client._id == currentClient.value : true
          let statusMatch = status.includes(task.status.name)
          return isCurrentClient && statusMatch && inPeriod(task.date)
        });
      }
      return result;
    }
  })

  const initCommon = async () => {
    await store.dispatch('FETCHtaskCategory')
    await store.dispatch('FETCHtasks')
    const DateNowFrom = new Date()
    const DateNowTo = new Date()

    let periodFrom, periodTo
    if (DateNowFrom.getDate() < 10) {
      periodFrom = new Date()
      periodFrom.setMonth(DateNowFrom.getMonth(DateNowFrom) - 1)
      periodFrom.setDate(1)
      periodTo = DateNowTo
      periodTo.setDate(10)
    } else {
      periodFrom = DateNowFrom
      periodFrom.setDate(1)
      periodTo = DateNowTo
    }
    period.value = {
      from: periodFrom,
      to: periodTo
    }


  }

  const cancelTask = async (taskId) => {
    const confirmFunc = async () => {
      let response = await store.dispatch('CANCELtask', taskId)
      if (response.status) {
        await store.dispatch('FETCHtasks')
      } else {
        warningBus.emit('show', ['Ошибка. Повторите позже.', response.data])
      }
    }
    let taskIndex = tasks.value.findIndex((item) => item._id == taskId)
    let taskDesc = tasks.value[taskIndex].desc
    warningBus.emit('confirm', { 'func': confirmFunc, 'msgs': ['Вы действительно хотите отменить заявку?', taskDesc] })
  }

  const modalShow = (status) => {
    const myModal = new bootstrap.Modal(
      document.getElementById("modal-task-request"),
      {}
    );
    if (status) {
      myModal.show();
    } else {
      myModal.hide()
    }
  }

  onMounted(() => {
    initCommon()
    intervalId.value = window.setInterval(() => {
      //initCommon();
    }, 15000);
  })

  onUnmounted(async () => {
    console.log("onUnmounted");
    clearInterval(intervalId.value);
  });





  return {
    initCommon,
    taskCategories,
    tasks,
    dateFormat,
    modalShow,
    tasksFilter,
    searchText,
    currentClient,
    cancelTask,
    period,
    showPeriod,
  }
}
