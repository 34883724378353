<template>
  <div>
    <h3>Статьи требующие проверки</h3>
    <button class="btn btn-light" @click="getRequestList()">Обновить</button>
    <Spinner v-if="loadSpinner" />
    <div v-for="article in requestList" :key="article._id">
      <div>
        <button class="btn btn-link" @click="openArticle(article._id)">
          <h3>{{ article.label }}</h3>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Spinner from "@/components/Spinner.vue";
import { useRouter } from "vue-router";

export default {
  name: "ConfluenceRequestList",
  components: { Spinner },
	props: {},
  setup(props, {emit}) {
    const store = useStore();
    const loadSpinner = ref(false);
    const requestList = ref([]);
    const router = useRouter();

    const getRequestList = async () => {
      loadSpinner.value = true;
      let requestListResp = await store.dispatch("FETCHconfluenceRequestList");
      if (requestListResp.status) {
        requestList.value = requestListResp.data;
      }
      loadSpinner.value = false;
    };

    const openArticle = (articleId) => {
      console.log(articleId);
      router.push({
        name: "confluence",
        params: { articleId: articleId,  },
      });
			emit("onOpen", true)
    };

    onMounted(() => {
      getRequestList();
    });

    return {
      loadSpinner,
      requestList,
      getRequestList,
      openArticle,
    };
  },
};
</script>
