<template>
  <div>
    <h2>Выполняется вход в личный кабинет...</h2>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "LoginByToken",
  components: {},
  props: { access: { default: "" }, refresh: { default: "" } },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      if (props.access && props.refresh) {
        let tokenData = {
          access: props.access,
          refresh: props.refresh
        }
        store.commit('updateToken', tokenData)
        await store.dispatch("FETCHuserProfile", { userId: null, phone: null });
        router.push({name: 'confluence'})
      }
    });
  },
};
</script>
