<template>
  <div>
    <div
      v-if="jwtAccessValid() && userProfile.checked"
      class="home container pt-5 pb-5"
    >
      <h3>Профиль компании</h3>

      <div style="max-width: 1000px">
        <div class="mb-3 row">
          <div class="col-sm-7">
            <div class="input-group">
              <label for="category" class="col-sm-3 col-form-label label-class"
                >Организация</label
              >
              <select
                id="category"
                v-model="currentClient"
                class="form-select"
                required
              >
                <option value=""></option>
                <option
                  v-for="client in userProfile.clients"
                  :key="client.client._id"
                  :value="client.client._id"
                >
                  {{ client.client.fullname }}
                </option>
              </select>
              <button class="btn btn-warning" @click="currentClient = ''">
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <its-hours v-if="false" :client="currentClient" />
      </div>

      <div>
        <client-services :client="currentClient" />
      </div>

      <div class="mt-5">
        <doc-request :client="currentClient" />
      </div>

      <div class="login-img">
        <router-link
          v-if="!jwtAccessValid()"
          class="mt-3 btn btn-warning w-50 fs-3"
          aria-current="page"
          :to="{ name: 'login' }"
          >Вход</router-link
        >
      </div>
    </div>
    <div v-else class="text-center">
      <router-link class="link" :to="{ name: 'login' }">
        <img v-if="isMobile" src="@/assets/wellcome-mobile.png" alt="Личный кабинет клиента ПРОФКЕЙС" style="max-width:100%"/>
        <img v-if="!isMobile" src="@/assets/wellcome.png" alt="Личный кабинет клиента ПРОФКЕЙС" style="max-width:100%"/>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import useCommonMixin from "@/components/commonMixin.js";
import ClientServices from "@/components/ClientServices.vue";
import DocRequest from "@/components/DocRequest.vue";
import ItsHours from "@/components/ItsHours.vue";

import { ref } from "vue";

export default {
  components: {
    ClientServices,
    DocRequest,
    ItsHours,
  },
  setup() {
    const { isPasswordTemp, jwtAccessValid, userProfile, isMobile } = useCommonMixin();
    const currentClient = ref();
    return {
      jwtAccessValid,
      isPasswordTemp,
      currentClient,
      userProfile,
      isMobile,
    };
  },
};
</script>

<style>
.login-img {
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.login-img img {
  width: 100%;
  height: auto;
}
</style>