import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue'

export default () => {
  const store = useStore()
  const confluenceCategories = computed(() => store.getters.confluenceCategories)
  const confluenceCurrentCategory = computed(() => store.getters.confluenceCurrentCategory)
  const confluenceEditMode = computed(() => store.getters.confluenceEditMode)

  const setEditMode = (mode) => {
    store.commit('SETConfluenceEditMode', mode)
  }

  const categoryObj = ref(null)

  const getCategoryById = (categoryId) => {
    categoryObj.value = null

    for (let index = 0; index < confluenceCategories.value.length; index++) {
      const element = confluenceCategories.value[index];
      if (element._id == categoryId) {
        categoryObj.value = element
        //return categoryObj
        break
      }
      searchConfluenceCategoryChildren(element, categoryId)
    }

    return categoryObj.value
  }

  const searchConfluenceCategoryChildren = (currentCategory, categoryId) => {
    for (let index = 0; index < currentCategory.children.length; index++) {
      let element = currentCategory.children[index];
      if (element._id == categoryId) {
        categoryObj.value = element
        //return categoryObj
        break
      }
      searchConfluenceCategoryChildren(element, categoryId)
    }
  }

  const onOpenArticle = async (articleId) => {
    console.log('onOpenArticle', articleId);
    await store.dispatch('POSTvisitArticle', articleId)
  }

  const postRatingArticle = async (articleId, rating) => {
    console.log('postRatingArticle', articleId, rating);
    let ratingData = {
      rating: rating,
      articleId: articleId,
    }
    let result = await store.dispatch('POSTratingArticle', ratingData)
    return result
  }


  onMounted(async () => {
    //await store.dispatch("FETCHconfluenceCategories")
  })

  return {
    confluenceCategories,
    confluenceCurrentCategory,
    confluenceEditMode,
    setEditMode,
    getCategoryById,
    onOpenArticle,
    postRatingArticle,
  }
}