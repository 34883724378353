import { computed } from 'vue'
import { useStore } from 'vuex';
import { ref } from "vue";
import { inject } from "vue";

export default () => {
  const store = useStore()
  const users = ref([]);
  const searchText = ref("");
  const warningBus = inject("warningBus"); // Inject `emitter`

  const init = async () => {
    await store.dispatch("FETCHroles");
    let response = await store.dispatch("FETCHuser");
    response.data.forEach((item) => {
      if ('phone' in item && item.phone) {
        item.phone = item.phone.slice(2, 12)
      }
      item.password = ''
    })
    users.value = response.status ? response.data : [];
  };

  const postUser = async (user, event) => {
    event.preventDefault();
    if (!user._id) {
      console.log('singup');
      user.inn = user.roles[0].client.inn;
      user.passwordTemp = true
      let singupUser = await store.dispatch("singup", user);
      // console.log(singupUser);
      if (singupUser.success) {
        warningBus.emit('show', [`Пользователю ${user.fullname} отправлено приглашение на ${user.email}.`,])
        init()
      } else {
        warningBus.emit('show', [`Ошибка создания нового пользователя.`, singupUser.data.data.message])
      }
    } else {
      console.log('POSTuser');
      let response = await store.dispatch("POSTuser", user);
      if (response.status) {
        warningBus.emit('show', [`Пользователь ${user.fullname} сохранен.`])
        init()
      }
    }
  };

  const removePermission = (user, roleIndex) => {
    if (user._id) {
      user.roles[roleIndex].delete = true;
    }
  };

  const addPermission = (user) => {
    console.log('user', user);

    if (
      user._id ||
      (!user._id && user.roles == 0)
    ) {
      user.roles.push({
        client: {
          inn: "",
          fullname: "",
        },
        role: "",
        permissionId: null,
      });
    }
  };


  const usersFilter = computed(() => {
    return (client) => {
      let result = [];

      if (client != "") {
        result = users.value.filter(user => {
          return JSON.stringify(user).toLowerCase().indexOf(client.toLowerCase()) >= 0 && !user.delete
        });
      } else {
        result = users.value.filter(user => !user.delete);
      }

      if (searchText.value.length >= 2) {
        result = result.filter(user => {
          return JSON.stringify(user).toLowerCase().indexOf(searchText.value.toLowerCase()) >= 0 && !user.delete
        });
      }
      return result;
    }

  });

  const addUser = () => {
    users.value.unshift({
      active: false,
      checked: false,
      email: "",
      fullname: "",
      phone: "",
      roles: [
        {
          client: {
            inn: "",
          },
        },
      ],
      password: "",
      passwordTemp: true,
      setPassword: true,
      edit: true,
      _id: "",
    });
  };

  const removeUser = async (user) => {
    user.delete = true;
    let remove = await store.dispatch("POSTuser", user);
    user.delete = remove.status ? true : false;
  };


  return {
    init,
    postUser,
    removePermission,
    addPermission,
    usersFilter,
    addUser,
    users,
    searchText,
    removeUser,
  }
}
