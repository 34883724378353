<template>
  <div class="container" id="its-hours" v-if="itsDogovor">
    <div class="row">
      <label class="col-sm-5 col-form-label label-class"
        >Остаток часов по договору "{{itsDogovor}}"
      </label>
      <div class="col-sm-1 label-class">
        <input class="form-control" disabled :value="itsHours" />
      </div>
    </div>
  </div>
</template>

<script>
import useCommonMixin from "@/components/commonMixin.js";
import { ref, watch } from "vue";

export default {
  components: {},

  props: {
    client: { default: "" },
  },

  setup(props) {
    const { userProfile } = useCommonMixin();
    const itsHours = ref(0);
    const itsDogovor = ref("");

    const getClient = () => {
      let clientIndex = userProfile.value.clients.findIndex(
        (item) => item.client._id == props.client
      );

      itsHours.value = 0;
      itsDogovor.value = "";

      if (clientIndex >= 0) {
        let client = userProfile.value.clients[clientIndex].client;
        itsHours.value = client.itsHours;
        itsDogovor.value = client.itsDogovor;
      }
    };

    watch(
      () => props.client,
      () => {
        getClient();
      }
    );

    return {
      userProfile,
      itsHours,
      itsDogovor,
    };
  },
};
</script>

<style scoped>
</style>