<template>
  <div class="container pt-1 pb-1 w-100" style="max-width: 700px">
    <div>
      <div class="p-0">
        <form @submit="postTask($event)" class="">
          <div class="mb-3 row">
            <label for="category" class="col-sm-3 col-form-label label-class"
              >Организация</label
            >
            <div class="col-sm-9 label-class">
              <div class="input-group">
                <select
                  id="category"
                  v-model="form.client"
                  class="form-select"
                  required
                >
                  <option
                    v-for="client in userProfile.clients"
                    :key="client.client._id"
                    :value="client.client._id"
                  >
                    {{ client.client.fullname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="category" class="col-sm-3 col-form-label label-class"
              >Категория обращения</label
            >
            <div class="col-sm-9 label-class">
              <div class="input-group">
                <select
                  id="category"
                  v-model="form.category"
                  class="form-select"
                  required
                >
                  <option
                    v-for="category in taskCategories"
                    :key="category._id"
                    :value="category._id"
                  >
                    {{ category.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="desc" class="col-sm-3 col-form-label label-class"
              >Укажите вкратце, в чем ваш вопрос</label
            >
            <div class="col-sm-9 label-class">
              <textarea
                id="desc"
                class="form-control"
                v-model="form.desc"
                required
                placeholder=""
                rows="3"
                cols="10"
              />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="contactUser" class="col-sm-3 col-form-label label-class"
              >Контактное лицо</label
            >
            <div class="col-sm-9 label-class">
              <div class="input-group">
                <select
                  id="contactUser"
                  v-model="form.contactUser"
                  class="form-select"
                  required
                  @change="contactUserInput"
                >
                  <option
                    v-for="user in contactUsers"
                    :key="user._id"
                    :value="user._id"
                  >
                    {{ user.fullname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="phone" class="col-sm-3 col-form-label label-class"
              >Телефон</label
            >
            <div class="col-sm-9 label-class">
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1">+7</span>
                <input
                  id="phone"
                  class="form-control"
                  v-model="form.contactPhone"
                  type="tel"
                  pattern="[0-9]{10}"
                  required
                  placeholder="1234567890"
                />
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label
              for="file"
              class="col-sm-3 col-form-label label-class"
            ></label>
            <div class="col-sm-9 label-class">
              <div class="input-group">
                <input
                  class="form-control"
                  type="file"
                  id="file"
                  accept="*/*"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <div class="col-sm-9 text-center w-100">
              <div class="btn-group">
                <button
                  type="submit"
                  class="btn btn-primary m-2"
                  :disabled="postTaskSpinner"
                >
                  Отправить заявку
                  <Spinner v-if="postTaskSpinner" />
                </button>
                <button
                  type="button"
                  class="btn btn-secondary m-2"
                  @click="hideTaskRequest"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import useSupportMixin from "@/components/supportMixin.js";
import useCommonMixin from "./commonMixin.js";
import { inject } from "vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "TaskRequest",
  props: {
    show: Boolean,
    client: String,
  },
  components: {Spinner},
  setup(props, { emit }) {
    const store = useStore();
    useCommonMixin();
    useSupportMixin();
    const { taskCategories } = useSupportMixin();
    const { userProfile } = useCommonMixin();
    const warningBus = inject("warningBus");

    const colleagues = ref([]);
    const clients = ref([]);
    const file = ref("");
    const form = ref({
      desc: "",
      contactPhone: userProfile.value.phone.slice(2, 12),
      contactUser: userProfile.value._id,
      client: props.client,
    });

    const postTaskSpinner = ref(false)

    const contactUsers = computed(() => {
      let users = [];
      if (form.value.client) {
        users = colleagues.value.filter(
          (user) => user.client == form.value.client
        );
      } else {
        users.push(userProfile.value);
      }
      return users;
    });

    const contactUserInput = () => {
      let userIndex = colleagues.value.findIndex((item) => {
        return item._id == form.value.contactUser;
      });
      if (userIndex >= 0) {
        form.value.contactPhone = colleagues.value[userIndex].phone.slice(
          2,
          12
        );
      }
    };

    const postTask = async (event) => {
      event.preventDefault();
      postTaskSpinner.value = true
      let responseTask = await store.dispatch("POSTtask", form.value);
      if (responseTask.status) {
        warningBus.emit("show", [
          "Ваша заявка отправлена!",
          "Ожидайте, с Вами свяжется наш специалист.",
        ]);
        form.value = {
          desc: "",
          contactPhone: userProfile.value.phone.slice(2, 12),
          contactUser: userProfile.value._id,
          client: "",
        };
        emit("update:show", false);
        await store.dispatch("FETCHtasks");
        if (file.value) {
          //console.log('uploadTaskFile');
          await store.dispatch("uploadTaskFile", {file: file.value, taskId: responseTask.data.data})
        }
        postTaskSpinner.value = false
      } else {
        warningBus.emit("show", [
          "Ошибка отправки. Попробуйте позже.",
          responseTask.data.data,
        ]);
      }
    };

    const hideTaskRequest = () => {
      emit("update:show", false);
    };

    const handleFileUpload = (event) => {
      //console.log('handleFileUpload', event);
      let NewFile = event.target.files[0];
      if (NewFile) {
        file.value = NewFile;
      }
      //console.log('file.value', file.value);
    };
    /*
    const uploadFile = async () => {
      if (file.value) {
        let response = await store.dispatch('uploadTaskFile', file.value)
        console.log('upload file', response);
      }
    }
*/
    const init = async () => {
      let getColleagues = await store.dispatch("FETCHсolleagues");
      if (getColleagues.status) {
        colleagues.value = getColleagues.data;
      } else {
        colleagues.value.push(userProfile.value);
      }
      clients.value = userProfile.value.clients;
    };

    onMounted(init);

    return {
      form,
      userProfile,
      taskCategories,
      contactUsers,
      clients,
      colleagues,
      contactUserInput,
      postTask,
      hideTaskRequest,
      handleFileUpload,
      file,
      postTaskSpinner,
    };
  },
};
</script>

<style>
.label-class {
  display: flex;
  align-items: center;
}
</style>
