<template>
  <div
    class="container pt-5 pb-5"
    v-if="jwtAccessValid() && userProfile.checked"
  >
    <h3>Пользователи</h3>
    
      <div class="mb-3 row">
        <div class="col-sm-7">
          <div class="input-group">
            <label for="category" class="col-sm-3 col-form-label label-class"
              >Организация</label
            >
            <select
              id="category"
              v-model="currentClient"
              class="form-select"
              required
            >
              <option value=""></option>
              <option
                v-for="client in userProfile.clients"
                :key="client.client._id"
                :value="client.client._id"
              >
                {{ client.client.fullname }}
              </option>
            </select>
            <button class="btn btn-warning" @click="currentClient = ''">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
      </div>
    
    
    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="users-tab"
          data-bs-toggle="tab"
          data-bs-target="#users"
          type="button"
          role="tab"
          aria-controls="users"
          aria-selected="true"
          @click="showPeriod = false"
        >
          Пользователи
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="registrationRequests-tab"
          data-bs-toggle="tab"
          data-bs-target="#registrationRequests"
          type="button"
          role="tab"
          aria-controls="registrationRequests"
          aria-selected="false"
          @click="showPeriod = true"
        >
          Запросы на регистрацию
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <!--Пользователи-->
      <div
        class="tab-pane fade show active"
        id="users"
        role="tabpanel"
        aria-labelledby="users-tab"
      >
        <users-card :client="currentClient" v-if="isMobile && isAdmin" />
        <users-table :client="currentClient" v-if="!isMobile && isAdmin" />
      </div>

      <!--Запросы на регистрацию-->
      <div
        class="tab-pane fade"
        id="registrationRequests"
        role="tabpanel"
        aria-labelledby="registrationRequests-tab"
      >
        <registration-request-card :client="currentClient" v-if="isMobile" />
        <registration-request-table :client="currentClient" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import UsersCard from "@/components/UsersCard.vue";
import UsersTable from "@/components/UsersTable.vue";
import useCommonMixin from "./commonMixin.js";
import RegistrationRequestCard from "@/components/RegistrationRequestCard.vue";
import RegistrationRequestTable from "@/components/RegistrationRequestTable.vue";

export default {
  name: "Users",
  components: {
    UsersCard,
    UsersTable,
    RegistrationRequestCard,
    RegistrationRequestTable,
  },
  setup() {
    useCommonMixin();
    const { isAdmin, jwtAccessValid, userProfile } = useCommonMixin();

    const isMobile = computed(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    });

    const currentClient = ref('')

    return {
      isMobile,
      isAdmin,
      jwtAccessValid,
      userProfile,
      currentClient,
    };
  },
};
</script>

<style>
</style>UsersTable