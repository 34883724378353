import axios from 'axios';

export function HTTP(token=null) {
  return axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'x-access-token': (token) ? `${token}`: '',
      },
    })
} 

export const AUTH = axios.create({
  baseURL: process.env.VUE_APP_AUTH_BASE_URL,
  headers: {
  }
})

export function AUTHjwt(token=null) {
  return axios.create({
      baseURL: process.env.VUE_APP_AUTH_BASE_URL,
      headers: {
        'x-access-token': (token) ? `${token}`: '',
      },
    })
} 