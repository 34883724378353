<template>
  <div
    class="container pt-5 pb-5 d-flex justify-content-center"
    style="max-width: 300px"
  >
    <div class="shadow bg-body rounder p-4">
      <h3 class="text-center mb-3">
        <span v-if="!redirect"> Вход </span>
        <span v-else> Ваш email подтвержден, выполните вход </span>
      </h3>
      <form @submit="onSubmit">
        <div class="mb-3 row">
          <label for="username" class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <input
              id="username"
              class="form-control"
              v-model="form.username"
              type="text"
              required
              placeholder=""
              @input="status.success = true"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="password" class="col-sm-3 col-form-label">Пароль</label>
          <div class="col-sm-9">
            <input
              id="password"
              class="form-control"
              v-model="form.password"
              type="password"
              required
              placeholder=""
              @input="status.success = true"
            />
          </div>
        </div>
        <div class="row">
          <div class="btn-group" role="group">
            <button type="submit" class="mx-2 btn btn-primary">Вход</button>
            <router-link
              class="mx-2 btn btn-light"
              aria-current="page"
              :to="{ name: 'singup' }"
              >Регистрация</router-link
            >
          </div>
          <button class="mx-2 mt-3 btn btn-light" @click="restorePassword">
            Восстановить пароль
          </button>

          <div v-if="!status.success" class="text-center mt-3">
            <span class="text-danger">
              <span v-if="status.err">
                {{ status.err }}
              </span>
              {{Object.keys(status.err).length}}
              <span v-if="status.err == ''"> Не верные имя или пароль </span>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/*eslint-disable no-unused-vars*/
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { inject } from "vue";

export default {
  name: "Login",
  components: {},
  props: {
    redirect: { default: "" },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const warningBus = inject("warningBus");

    const form = ref({
      username: "",
      password: "",
    });
    const status = ref({
      success: true,
      err: "",
    });

    const onSubmit = async (evt) => {
      evt.preventDefault();
      status.value = await store.dispatch("obtainToken", form.value);
      if (status.value.success) {
        router.push({ name: "home" });
        //document.location.reload(true);
      }
    };

    const restorePassword = async () => {
      let response = await store.dispatch("restorePassword", form.value);
      if (response.status) {
        warningBus.emit("show", [`На Вашу почту отправлен временный пароль.`]);
      }
      // } else {
      //   warningBus.emit("show", [`Ошибка.`, response.data.message]);
      // }
    };

    return {
      status,
      store,
      form,
      onSubmit,
      restorePassword,
    };
  },
};
</script>

<style>
</style>

