<template>
  <div id="modal-msg" class="modal justify-content-center" tabindex="-1">
    <div class="vertical-alignment-helper">
      <div class="modal-dialog vertical-align-center">
        <div class="modal-content">
          <div class="modal-body berry">
            <ul class="list-group">
              <li
                v-for="(msg, index) in messages"
                :key="index"
                class="list-group-item"
              >
                {{ msg }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <div v-if="confirm" class="btn-group">
              <button type="button" class="btn btn-danger m-1"  data-bs-dismiss="modal" @click="yesFunc">
                Да
              </button>
              <button
                type="button"
                class="btn btn-secondary m-1"
                data-bs-dismiss="modal"
              >
                Нет
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, inject } from "vue";
import * as bootstrap from "bootstrap";

export default {
  name: "WarningMsg",
  components: {},
  setup() {
    const messages = ref(["Test-1", "Test-2"]);
    const warningBus = inject("warningBus"); // Inject `emitter`
    const confirm = ref(false);
    let confirmFunc = () => {}

    const yesFunc = () => {
      confirmFunc()
    }

    const modalShow = () => {
      const myModal = new bootstrap.Modal(
        document.getElementById("modal-msg"),
        {}
      );
      myModal.show();
    };

    warningBus.on("show", (msgs) => {
      // *Listen* for event
      confirm.value = false
      confirmFunc = () => {}
      if (Array.isArray(msgs)) {
        messages.value = msgs;
        modalShow();
      }
    });

    warningBus.on("confirm", ({ func, msgs }) => {
      // *Listen* for event
      confirm.value = true;
      confirmFunc = func;
      if (Array.isArray(msgs)) {
        messages.value = msgs;
        modalShow();
      }
    });

    return {
      messages,
      confirm,
      confirmFunc,
      yesFunc,
    };
  },
};
</script>